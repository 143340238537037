<template>
    <v-col v-if="!navbarra && !IDcuestionario" cols="12">
        <v-card class="mx-4">
            <!-- Button for 'mirar' option -->
            <v-btn block color="primary" v-if="selectedOption === 'editar'" @click="openDialog(type = 'crear')">
                {{ t('Crear') }} {{ t('cuestionario') }}
            </v-btn>
        </v-card>
    </v-col>
    <template v-if="IDcuestionario">
        <v-col v-if="!navbarra" cols="12">
            <v-card class="mx-4">
                <!-- Button for 'mirar' option -->
                <v-btn block color="primary" v-if="selectedOption === 'mirar'" @click="accederCuestionario">
                    {{ t('Ponte_a_prueba') }}
                </v-btn>
                <!-- Button for 'editar' option -->
                <v-btn block color="primary" v-if="selectedOption === 'editar'" @click="openPreguntasDialog">
                    {{ t('Editar') }} {{ t('preguntas') }}
                </v-btn>
            </v-card>
        </v-col>
        <template v-if="navbarra">
            <v-btn v-if="selectedOption === 'mirar'" :icon="mdiIcon" :color="starColor" variant="text"
                @click="accederCuestionario"></v-btn>
            <v-btn v-if="selectedOption === 'editar'" icon="mdi-star" color="primary" variant="text"
                @click="openPreguntasDialog"></v-btn>
        </template>
    </template>

    <v-dialog v-model="overlay" class="align-center justify-center" persistent fullscreen scrollable>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ cuestionario.titulo }}</v-toolbar-title>
                <v-btn icon @click="cerrarOverlay">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-progress-linear color="primary" :model-value="progress" :height="12"></v-progress-linear>
            <HelperCarregant v-if="loading" :missatge="t('Cargando') + ' ' + t('datos')" />
            <v-card-text v-if="!loading" style="overflow-y: auto;">
                <template v-if="!feedback">
                    <v-window disabled v-model="onboarding">
                        <v-window-item v-for="pregunta in cuestionario.preguntas" :key="pregunta.id">
                            <div v-if="pregunta.tipo === 'ordenar'">
                                <PreguntaOrdenar :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                            </div>
                            <div v-if="pregunta.tipo === 'relacionar'">
                                <PreguntaRelacionar :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                            </div>
                            <div v-if="pregunta.tipo === 'relacionar_cols'">
                                <PreguntaRelacionarCols :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                            </div>
                            <div v-if="pregunta.tipo === 'multiple_choice'">
                                <PreguntaMultipleChoice :pregunta="pregunta" @respuesta-enviada="handleRespuesta" />
                            </div>
                        </v-window-item>
                    </v-window>
                </template>
                <v-window class="d-flex justify-center align-center" v-if="feedback">
                    <v-container style="height: 400px;">
                        <v-row align-content="center" class="fill-height" justify="center">
                            <v-rating size="72" v-model="rating" length="3" :active-color="ratingDetails.color"
                                color="grey" readonly>
                            </v-rating>
                            <v-col class="text-subtitle-1 text-center" cols="12">
                                {{ ratingDetails.message }}
                            </v-col>
                            <v-btn @click="cerrarOverlay" color="primary">
                                {{ t('Continua') }}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-window>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Diálogo para crear un nuevo cuestionario -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ dialogType === 'crear' ? t('Crear') : t('Editar') }} {{ t('cuestionario')
                    }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" @click="dialogType === 'crear' ? crearCuestionario() : editarCuestionario()"
                    prepend-icon="mdi-content-save">
                    {{ dialogType === 'crear' ? t('Crear') : t('Guardar') }}
                </v-btn>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <HelperCarregant v-if="loading" :missatge="t('Creando') + ' ' + t('cuestionario')" />
                <template v-if="!loading">
                    <v-text-field v-model="nuevoCuestionario.titulo" :label="t('Título')" required></v-text-field>
                    <v-text-field type="number" v-model="nuevoCuestionario.num_preguntas"
                        :label="t('Número_de') + ' ' + t('preguntas')" required></v-text-field>
                    <v-expansion-panels class="mt-4">
                        <v-expansion-panel :title="t('Opciones_adicionales')">
                            <v-expansion-panel-text>
                                <v-select v-model="nuevoCuestionario.tipo_reto" :items="['normal', 'contrareloj']"
                                    :label="t('Tipo_de_reto')" required></v-select>
                                <v-btn v-if="dialogType === 'editar'" color="red" text prepend-icon="mdi-delete"
                                    @click="confirmarEliminacion">
                                    {{ t('Eliminar') }}
                                </v-btn>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Diálogo para el CRUD de preguntas -->
    <v-dialog v-model="preguntasDialog" persistent>
        <AdminPreguntes :cuestionario="cuestionario" :loading="loading" @cerrar-preguntas="closePreguntasDialog"
            @detalles-cuestionario="openDialog('editar')" @actualizar-preguntas="fetchCuestionario" />
    </v-dialog>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { axios } from '../axios';
import PreguntaOrdenar from './PreguntaOrdenar.vue';
import PreguntaRelacionar from './PreguntaRelacionar.vue';
import PreguntaRelacionarCols from './PreguntaRelacionarCols.vue';
import PreguntaMultipleChoice from './PreguntaMultipleChoice.vue';
import AdminPreguntes from './AdminPreguntes.vue';
import HelperCarregant from './HelperCarregant.vue';

export default {
    emits: ['respuesta-enviada'],
    props: {
        IDcuestionario: String,
        IDleccion: String,
        selectedOption: String,
        navbarra: Boolean,
        nota_usuario: {
            type: Object,
            default: () => ({})
        },
    },
    components: {
        PreguntaOrdenar,
        PreguntaRelacionar,
        PreguntaMultipleChoice,
        PreguntaRelacionarCols,
        AdminPreguntes,
        HelperCarregant,
    },
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            cuestionario: {
                type: Object,
                default: () => ({ preguntas: [] })
            },
            overlay: false,
            onboarding: 0,
            progress: 5,
            loading: false,
            preguntasIDs: [],
            resultat: [],
            feedback: false,
            rating: 0,
            dialog: false,
            preguntasDialog: false,
            dialogType: '',
            nuevoCuestionario: {
                leccion: null,
                titulo: '',
                tipo_reto: 'normal',
                num_preguntas: 10,
                tiempo_contrareloj: 60,
                bonus: 5,
                penalizacion: 10
            },
            snackbarText: '',
        }
    },
    computed: {
        ratingDetails() {
            let details = {
                color: 'grey', // Color por defecto
                message: this.t('Inténtalo_de_nuevo'),
            };

            switch (this.rating) {
                case 3:
                    details.color = 'amber-accent-2';
                    details.message = this.t('Excelente');
                    break;
                case 2:
                    details.color = 'blue-grey-lighten-2';
                    details.message = this.t('Buen_esfuerzo');
                    break;
                case 1:
                    details.color = 'deep-orange-lighten-2';
                    details.message = this.t('Aceptable_aunque_puedes_mejorar');
                    break;
            }
            return details;
        },
        starColor() {
            if (!this.nota_usuario || this.nota_usuario.nota === null) {
                return 'grey'; // Color predeterminado si no hay nota
            }
            const nota = Math.round(this.nota_usuario.nota);
            if (nota >= 9) {
                return 'amber-accent-2'; // Excelente
            } else if (nota >= 8) {
                return 'blue-grey-lighten-2'; // Bueno
            } else if (nota >= 7) {
                return 'deep-orange-lighten-2'; // Aceptable
            } else {
                return 'red'; // Mejorable
            }
        },
        mdiIcon() {
            if (!this.nota_usuario || this.nota_usuario.nota === null) {
                return 'mdi-progress-question'; // Color predeterminado si no hay nota
            } else {
                const nota = Math.round(this.nota_usuario.nota);
                if (nota >= 10) {
                    return 'mdi-star-shooting';
                } else if (nota < 7) {
                    return 'mdi-close-circle-outline';
                } else {
                    return 'mdi-star';
                }
            }
        },
    },
    methods: {
        openPreguntasDialog() {
            this.fetchCuestionario();
            this.preguntasDialog = true;
        },
        closePreguntasDialog() {
            this.preguntasDialog = false;
        },
        async fetchCuestionario() {
            if (!this.IDcuestionario) {
                console.log('No se proporcionó ID de cuestionario.');
                return;  // Salir de la función si no hay un ID válido
            }
            this.feedback = false;
            this.loading = true;
            try {
                const response = await axios.get(`cuestionario/${this.IDcuestionario}/`);
                this.cuestionario = response.data;
                // Extraer las IDs de las preguntas
                this.preguntasIDs = this.cuestionario.preguntas.map(pregunta => pregunta.id);
                this.loading = false;
                this.progress = (1 / this.cuestionario.preguntas.length) * 100
            } catch (error) {
                this.cerrarOverlay();
                this.$store.dispatch('triggerSnackbar', { message: error, color: 'error' });
                this.loading = false;
            }
        },
        async evaluarCuestionario() {
            this.loading = true;
            this.feedback = true;
            const datosParaEnviar = {
                IDcuestionario: this.IDcuestionario,
                preguntasIDs: this.preguntasIDs
            };
            try {
                const response = await axios.post(`evaluar/`, datosParaEnviar, {
                });
                this.resultat = response.data;
                this.rating = Math.round(this.resultat.nota) - 7;
                this.loading = false;
                this.$emit('respuesta-enviada');
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    // Si el error es 403 Forbidden, el usuario no tiene acceso
                }
                this.$store.dispatch('triggerSnackbar', { message: error, color: 'error' });
                this.cerrarOverlay();
                this.loading = false;
            }
        },
        async handleRespuesta() {
            this.next();
        },
        next() {
            if (this.onboarding + 1 >= this.cuestionario.preguntas.length) {
                // Close the overlay and reset onboarding to the start (or handle it differently if needed)
                this.evaluarCuestionario();
            } else {
                this.onboarding = this.onboarding + 1;
                this.progress = (this.onboarding + 1 / this.cuestionario.preguntas.length) * 100
            }
        },
        accederCuestionario() {
            this.fetchCuestionario();
            this.feedback = false;
            this.onboarding = 0;
            this.overlay = true;
        },
        cerrarOverlay() {
            this.overlay = false;
            this.progress = 5;
        },
        async editarCuestionario() {
            this.loading = true;
            this.nuevoCuestionario.leccion = this.IDleccion
            try {
                const response = await axios.put(`cuestionarios/${this.IDcuestionario}`, this.nuevoCuestionario);
                this.cuestionario = response.data;
                this.snackbarText = this.t("Cuestionario") + ' ' + this.t("actualizado_con_éxito");
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
                this.dialog = false;  // Cerrar el diálogo después de editar
                this.loading = false;
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
                this.loading = false;
            }
        },
        openDialog(type) {
            this.dialogType = type;
            this.dialog = true;
            if (type === 'editar' && this.cuestionario) {
                this.nuevoCuestionario = {
                    ...this.nuevoCuestionario,  // Mantiene valores por defecto y actualiza con los del cuestionario existente
                    titulo: this.cuestionario.titulo,
                    tipo_reto: this.cuestionario.tipo_reto,
                    num_preguntas: this.cuestionario.num_preguntas,
                    tiempo_contrareloj: this.cuestionario.tiempo_contrareloj || this.nuevoCuestionario.tiempo_contrareloj, // Coge el valor por defecto si no está definido en el cuestionario
                    bonus: this.cuestionario.bonus || this.nuevoCuestionario.bonus,
                    penalizacion: this.cuestionario.penalizacion || this.nuevoCuestionario.penalizacion
                };
            } else {
                this.nuevoCuestionario = {
                    leccion: this.IDleccion,
                    titulo: '',
                    tipo_reto: 'normal',
                    num_preguntas: 10,
                    tiempo_contrareloj: 90,
                    bonus: 5,
                    penalizacion: 10
                };
            }
        },
        async crearCuestionario() {
            this.loading = true;
            try {
                const response = await axios.post(`cuestionarios/`, this.nuevoCuestionario);
                this.cuestionario = response.data;
                this.$emit('nuevo-cuestionario', { data: response.data.id });
                this.$store.dispatch('triggerSnackbar', { message: this.t("Cuestionario") + ' ' + this.t("creado_con_éxito"), color: 'success' });
                this.loading = false;
                this.openPreguntasDialog();
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
                this.loading = false;
            }
            this.dialog = false;
        },
        confirmarEliminacion() {
            if (confirm('¿Estás seguro de que quieres eliminar este cuestionario? Esta acción no se puede deshacer.')) {
                this.eliminarCuestionario();
            }
        },

        // Método para eliminar el cuestionario
        async eliminarCuestionario() {
            this.loading = true;
            try {
                await axios.delete(`cuestionarios/${this.IDcuestionario}`);
                this.loading = false;
                this.snackbarText = this.t("Cuestionario") + ' ' + this.t("eliminado_con_éxito");
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
                this.$emit('eliminar-cuestionario');
                this.closePreguntasDialog();
            } catch (error) {
                this.$store.dispatch('triggerSnackbar', { message: error, color: 'error' });
                this.loading = false;
            }
            this.dialog = false;
        },
    },
}
</script>